<template>
  <MemberSettingContainer>
    <b-card class="pl-2 pr-2 pt-1">
      <div class="d-flex mb-2">
        <h4>
          {{ $t("mylikes.title") }} 
          <BBadge v-if="metaLikes.total > 0">
            {{ metaLikes.total }}
          </BBadge>
        </h4>
      </div>

      <!-- <ul>
          <li v-for="like in likes" :key="like.likedAt">
            {{ like.element.key }} - {{ like.element.message }}
          </li>
        </ul> -->
      <div class="infinity-loading-header">
        <div class="p-1 text-center">
          {{ $t('mylikes.table.element') }}
        </div>
        <div class="p-1 text-center">
          {{ $t('mylikes.table.type') }}
        </div>
        <div class="p-1 text-center">
          {{ $t('mylikes.table.likedAt') }}
        </div>
      </div>
      <div class="wrapper-infinity-loading">
        <div v-for="(like, i) in likes" :key="like.likedAt + i" class="item-infinity-loading">
          <!-- {{ like.element.key }} - {{ like.element.message }} -->
          <div class="p-1">
            <BCard>
              <BCardText v-html="like.element.message" />
            </BCard>
          </div>
          <div class="p-1 text-center">
            {{ like.elementType }}
          </div>
          <div class="p-1 text-center" v-bind:title="$moment(like.likedAt)">
            {{ $moment(like.likedAt).fromNow() }}
          </div>
        </div>
        <InfiniteLoading @infinite="infiniteHandler">
          <!-- <div slot="spinner">Loading...</div> -->
          <div class="my-2" slot="no-more">
            {{ $t("mylikes.table.nomore") }}
          </div>
          <div class="my-2" slot="no-results">
            {{ $t("mylikes.table.noresults") }}
          </div>
        </InfiniteLoading>
      </div>
    </b-card>
  </MemberSettingContainer>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import { mapGetters } from 'vuex';
import { getLikes } from '@/services/backend/likes/likesService';
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';

export default {
  name: "MyLikes",

  components: {
    MemberSettingContainer,
    InfiniteLoading,
  },

  props: {
    communityId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      likes: [],
      metaLikes: {
        current_page: 0,
        per_page: 10,
        total: 0,
      },
    };
  },

  computed: {
    ...mapGetters(['loggedMember']),
  },

  methods: {
    async infiniteHandler($state) {
      this.metaLikes.current_page += 1;
      const response = await getLikes({
        communitySlug: this.communityId,
        memberKey: this.loggedMember.key,
        page: this.metaLikes.current_page,
      });
      // console.log('RESPONSE: ', response);
      if (response.data.length) {
        this.likes.push(...response.data);
        this.metaLikes = response.meta;
        $state.loaded();
      } else {
        $state.complete();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/base/core/colors/palette-variables.scss';

.infinity-loading-header {
  display: flex;
  width: 100%;
  //background-color: map-get($primary-color, 'base');
  background-color: hsl(0deg 0% 95.03%);
  //color: map-get($white-color, 'base');
  color: black;
  font-weight: 700;
  //border-radius: 1.2rem;
  div:nth-child(1) {
    width: 70%
  }
  div:nth-child(2) {
    width: 15%
  }
  div:nth-child(3) {
    width: 15%
  }
}
.wrapper-infinity-loading {
   height: 100%;
   max-height: 37.5rem;
   overflow-y: auto;

  .item-infinity-loading {
    // border: 1px solid blue;
    display: flex;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }

    > div {
      overflow: scroll;
      width: 70%
    }
    div:nth-child(2) {
      width: 15%
    }
    div:nth-child(3) {
      width: 15%
    }
   }
}
</style>
