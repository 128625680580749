import axiosBackend from '../axiosBackend';

export async function getLikes(params) {
  try {
    const { data } = await axiosBackend.get('/likes', { params });
    return data;
  } catch (error) {
    return error.response ? error.response : error;
  }
}

export default {
  getLikes,
};
